import React from 'react'
import BookingForm from "../components/BookingForm";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import BookingFormPlaces from '../components/BookingFormPlaces';
import TopRoutesGrid from '../components/Home/TopRoutesGrid'
import sedan from "../assets/images/cars/sedan.png";
import suv from "../assets/images/cars/suv.png";
const VelloreToChennai = () => {
  return (
    <div>
      <BookingFormPlaces />
      <div className='container mx-auto mt-5 px-5'>
        <h4 className='text-[30px] sm:text-[34px] md:text-[36px] font-semibold pt-8 text-center'>Top Drop Taxi point to point Routes</h4>
        <p className='text-[#494848] w-full  mt-2 text-center'>Book Online Outstation Taxi Service in Chennai and Vellore . One Way Drop Taxi @ One Way Fare.</p>
        <div className='grid grid-cols-1 md:grid-cols-2 mt-5 bg-[#f9f9f9] gap-5 p-4'>
          <TopRoutesGrid grid_image={sedan} heading="Seden" trip="Vellore to Chennai" rates="Rs. 2800" />
          <TopRoutesGrid grid_image={suv} heading="SUV" trip="Vellore to Chennai" rates="Rs. 3600" />

        </div>
      </div>
      <OurServices />
      <OneWayServices />
      <Look />

    </div>
  )
}

export default VelloreToChennai