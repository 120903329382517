import React from 'react'
import BookingForm from "../components/BookingForm";
import Look from "../components/Look";
import OurServices from "../components/Home/OurServices";
import OneWayServicesComponent from "../components/Home/OneWayServicesComponent";
import vellore from "../assets/images/services/vellore.jpg";
import banglore from "../assets/images/services/bangalore.jpg";
import TopRoutes from "../components/Home/TopRoutes";
import TopRoutesGrid from '../components/Home/TopRoutesGrid';
import sedan from "../assets/images/cars/sedan.png";
import suv from "../assets/images/cars/suv.png";
import BookingFormBanglore from '../components/BookingFormBanglore';
const VelloreToBanglore = () => {
    return (
        <div>
            <BookingFormBanglore />
            <div className='container mx-auto mt-5 px-5'>
        <h4 className='text-[30px] sm:text-[34px] md:text-[36px] font-semibold pt-8 text-center'>Top Drop Taxi Vellore to Banglore Routes</h4>
        <p className='text-[#494848] w-full  mt-2 text-center'>Book Online Outstation Taxi Service in Chennai and Vellore . One Way Drop Taxi @ One Way Fare.</p>
        <div className='grid grid-cols-1 md:grid-cols-2 mt-5 bg-[#f9f9f9] gap-5 p-4'>
          <TopRoutesGrid grid_image={sedan} heading="Seden" trip="Vellore to Banglore" rates="Rs. 4500" />
          <TopRoutesGrid grid_image={suv} heading="SUV" trip="Vellore to Banglore" rates="Rs. 7000" />

        </div>
      </div>
            <OurServices />
            <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 sm:px-4 md:px-8 lg:px-16 text-white" >
                <div className=" mt-5 md:mt-7 lg:mt-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
                    <OneWayServicesComponent place="VELLORE" desc="Vellore Dropme Taxi provides reliable transportation from 
           Chennai to Vellore, offering a diverse range of vehicles including SUVs, sedans, and Etios models. 
           Ensuring a comfortable journey, their well-maintained vehicles and skilled drivers cater to various 
           preferences, from budget-conscious travelers to those seeking luxury. Ideal for business, leisure, or 
           medical visits, they guarantee a seamless experience. Covering Vellore's key landmarks such as VIT 
           University, Christian Medical College, and Vellore Fort, they serve both tourists and locals. Whether 
           for a one-way trip or a round-trip adventure, their service offers flexibility. Operating through their
            website, they offer a user-friendly platform for online booking, ensuring convenience and security. 
            Committed to safety and customer satisfaction, Vellore Dropme Taxi stands out as a trusted transportation 
            provider, delivering a comfortable and enjoyable travel experience with their SUVs, sedans, and Etios 
            models." image={vellore} />
                    <OneWayServicesComponent place="Banglore" desc="
Vellore Dropme Taxi offers reliable transportation from Vellore to Bangalore, ensuring a smooth and comfortable journey. Our diverse fleet includes SUVs, sedans, and Etios models, catering to all preferences and budgets. Whether you’re traveling for business, leisure, or medical visits, our well-maintained vehicles and skilled drivers guarantee a pleasant experience.

During your trip, you can explore some of Bangalore’s key areas. MG Road is a bustling hub for shopping and dining, while Indiranagar boasts trendy cafes and boutiques. Koramangala is known for its vibrant nightlife and startup culture, and Whitefield is ideal for business travelers. If you enjoy nature, consider visiting Bannerghatta National Park for a chance to see wildlife.

Booking your ride is simple with our user-friendly online platform, ensuring convenience and security. Whether you need a one-way or round trip, Vellore Dropme Taxi provides the flexibility to meet your travel needs. Our commitment to safety and customer satisfaction sets us apart as a trusted transportation provider. Choose Vellore Dropme Taxi for your journey to Bangalore and experience the difference in comfort and service." image={banglore} />
                </div></div>
            <Look /></div>
    )
}

export default VelloreToBanglore