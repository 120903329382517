import React, { useEffect, useRef, useState } from "react";
import selct_car from "../assets/images/selct_car.svg";
import select_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
import { useForm } from "react-hook-form";
import indiaFlag from "../assets/images/indiaflag.png";
import what_img from "../assets/images/whatsapp.png";
import { MdAirlineSeatReclineNormal } from "react-icons/md";

import {
    Google,
    GoogleMap,
    useLoadScript,
    StandaloneSearchBox,
    GoogleApiWrapper,
    DistanceMatrixService,
} from "@react-google-maps/api";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUser } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";
import { MdLuggage } from "react-icons/md";
import { netbixTelegramChatId, telegramApi, telegramChatId, netbixTelegramApi } from "../config/config"
const libraries = ["places"];

const OneWayPlacesForm = ({ tripType, fromChange, drop_value, price1, price2 }) => {
    const [selectedDate, setSelectedDate] = useState("");
    const [finalMobileNumber, setFinalNumber] = useState();
    const [driverBeta, setDriverBeta] = useState(400);
    const [carList, setCarList] = useState(false);
    // const[carImge,setCarImage] =useState();
    const [listCars, setListCars] = useState({ car_amount: 0 });
    const [carReq, setCarReq] = useState(false);
    const [distanceResult, setDistanceResult] = useState({
        org_distance: null,
        org_duration: null,
    });

    const [carImages, setCarImages] = useState(null);

    const [google, setGoogle] = useState(null);
    const [whatsaappMsg, setWhatsaappMsg] = useState("");

    const [popup, setPopup] = useState(false);

    const [popupDis, setPopupDis] = useState(0);
    const [popupDur, setPopupDur] = useState("");
    const [popupCar, setPopupCar] = useState("");
    const [popupRate, setPopupRate] = useState(0);
    const [totRate, setTotalRate] = useState(0);
    const [loadingButton, setBoadingButton] = useState(true);
    function openCars() {
        setCarList(!carList);
    }
    const searchBox = useRef(null);

    const formRef = useRef(null);

    function sendWhatsapp() {
        document.body.classList.remove("dis_scroll");
        setPopup(false);
        window.open("https://wa.me/+919597256055?text=" + whatsaappMsg);
    }

    const handlePlacesChanged = () => {
        const places = searchBox.current.getPlaces();
        console.log(places);
    };

    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
    //     libraries,
    // });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();

    useEffect(() => {
        if (window.google) {
            setGoogle(window.google);
        }
    }, []);

    const onSubmit = async (data) => {

        var day = selectedDate.getDate();
        console.log(day);
        var month = selectedDate.getMonth() + 1;
        console.log(month);

        var year = selectedDate.getFullYear();
        console.log(year);
        var formattedDate = day + '/' + month + '/' + year;
        console.log(formattedDate);

        if (listCars.car_amount === 0) {
            setCarReq(true);
            return;
        } else {
            setCarReq(false);
        }
        if (
            listCars.car_amount !== "0" &&
            data.drop_add !== "" &&
            data.full_name !== "" &&
            data.pick_up_add !== "" &&
            selectedDate !== "" &&
            data.pickup_time !== ""
        ) {
            setBoadingButton(true);
            if (data.drop_add === data.pick_up_add) {
                return alert("Please select Different Places");
            }
            //   if (!google) {
            //     alert("Google Maps API is not loaded yet.");
            //     return;
            //   }
            setPopupCar(listCars.text);


            var front_url = "https://velloredropmetaxi.in/";

            // setPopupRate(parseInt(one_way_rate) + driverBeta);

            const whatsapp =
                "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " +
                data.full_name +
                " ,%0A%0AMobile Number  : +91" +
                data.mob_number +
                " ,%0A%0APickup Location  : " +
                data.pick_up_add +
                " ,%0A%0ADrop Location : " +
                data.drop_add +
                ",%0A%0APickup Date/Time: " +
                formattedDate +
                " " +
                data.pickup_time +

                //" ,%0A%0APassengers  : " +
                // data.passengers +
                // " ,%0A%0ALuggages  : " +
                // // data.luggages +
                // " ,%0A%0ARate Per KM: " +
                // listCars.car_amount +
                // " ,%0A%0ADriver Beta : " +
                // driverBeta +
                ",%0A%0ATotal fair: " +
                driverBeta +
                " ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +919597256055 %0A%0A" +
                front_url;
                   window.open("https://wa.me/+919597256055?text=" + whatsapp);
                  var message =
                "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
                data.full_name +
                " ,\n\nMobile Number  : +91" +
                data.mob_number +
                " ,\n\nPickup Location  : " +
                data.pick_up_add +
                " ,\n\nDrop Location : " +
                data.drop_add +
                // " ,\n\nPassengers : " +
                // data.passengers +
                // " ,\n\nLuggages : " +
                // data.luggages +
                ",\n\nPickup Date/Time: " +
                formattedDate +
                " " +
                data.pickup_time +

                // " ,\n\nRate Per KM: " +
                // listCars.car_amount +
                // " ₹,\n\nDriver Beta : " +
                // driverBeta +
                ",\n\nTotal Fair: " +
                driverBeta +

                " ,\n\nToll, parking, permit extra \n\nFor any questions please contact +919597256055 \n\n" +
                front_url;
            try {
                const response = axios.post(
                    `https://api.telegram.org/bot${telegramApi}/sendMessage`,
                    {
                        chat_id: telegramChatId,
                        text: message,
                    }
                );
                if (response) {

                    setListCars({
                        text: "",
                        car_amount: 0,
                    });
                    setBoadingButton(false);
                    formRef.current.reset();
                    document.body.classList.add("dis_scroll");

                    setSelectedDate(null);

                }
            } catch (error) {
                setBoadingButton(false);
                alert("Please try again");
                console.error("Error sending Telegram message:", error);
                // Handle the error
            }
            try {
                axios.post(
                    `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                    {
                        chat_id: netbixTelegramChatId,
                        text: message,
                    }

                );
                // if (response) {
                //   setListCars({
                //     text: "",
                //     imageSrc: "",
                //     car_amount: 0,
                //   });
                //   setBoadingButton(false);
                //   formRef.current.reset();
                //   document.body.classList.add("dis_scroll");
                //   setPopup(true);
                //   setSelectedDate(null);

                // }

            } catch (error) {
                console.error("Error sending Telegram message:", error);
                // Handle the error
            }
        } else {
            alert("Please Try again!");
        }

        //   const distanceMatrixService = new google.maps.DistanceMatrixService();

        //   const distanceMatrixOptions = {
        //     origins: [data.pick_up_add],
        //     destinations: [data.drop_add],
        //     travelMode: "DRIVING",
        //   };

        //   await distanceMatrixService.getDistanceMatrix(
        //     distanceMatrixOptions,
        //     (response, status) => {
        //       if (
        //         status === "OK" &&
        //         response.destinationAddresses[0].length !== 0 &&
        //         response.originAddresses[0].length !== 0
        //       ) {
        //         if (
        //           response.rows[0].elements[0].distance.text ||
        //           response.rows[0].elements[0].duration.text
        //         ) {
        //           var org_distance = response.rows[0].elements[0].distance.text;
        //           var org_duration = response.rows[0].elements[0].duration.text;
        //           setPopupDur(org_duration);
        //           setPopupDis(org_distance);
        //           setDistanceResult({ org_distance, org_duration });

        //           if (org_distance != null && org_duration != null) {
        //             var one_way_rate =
        //               parseInt(org_distance) * parseInt(listCars.car_amount);
        //             setTotalRate(
        //               parseInt(org_distance) * parseInt(listCars.car_amount)
        //             );
        //             if (parseInt(org_distance) <= 130) {
        //               one_way_rate = 130 * parseInt(listCars.car_amount);
        //               setTotalRate(130 * parseInt(listCars.car_amount));
        //             }
        //             var front_url = "https://velloredropmetaxi.in/";

        //             setPopupRate(parseInt(one_way_rate) + driverBeta);

        //             setWhatsaappMsg(
        //               "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " +
        //               data.full_name +
        //               " ,%0A%0AMobile Number  : +91" +
        //               data.mob_number +
        //               " ,%0A%0APickup Location  : " +
        //               data.pick_up_add +
        //               " ,%0A%0ADrop Location : " +
        //               data.drop_add +
        //               ",%0A%0ATrip Type: One Way ,%0A%0APickup Date/Time: " +
        //               data.pickup_date +
        //               " " +
        //               formattedDate +
        //               ",%0A%0ATotal KM : " +
        //               org_distance +
        //               " ,%0A%0ADuration : " +
        //               org_duration +
        //               //" ,%0A%0APassengers  : " +
        //               // data.passengers +
        //               // " ,%0A%0ALuggages  : " +
        //               // data.luggages +
        //               " ,%0A%0ARate Per KM: " +
        //               listCars.car_amount +
        //               " ₹,%0A%0ADriver Beta : " +
        //               driverBeta +
        //               " ₹  ,%0A%0ARate for Oneway : " +
        //               one_way_rate +
        //               "  ₹,%0A%0ATotal Trip Fare :" +
        //               (parseInt(one_way_rate) + driverBeta) +
        //               " ₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +919597256055 %0A%0A" +
        //               front_url
        //             );
        //             var message =
        //               "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
        //               data.full_name +
        //               " ,\n\nMobile Number  : +91" +
        //               data.mob_number +
        //               " ,\n\nPickup Location  : " +
        //               data.pick_up_add +
        //               " ,\n\nDrop Location : " +
        //               data.drop_add +
        //               // " ,\n\nPassengers : " +
        //               // data.passengers +
        //               // " ,\n\nLuggages : " +
        //               // data.luggages +
        //               ",\n\nTrip Type: One Way ,\n\nPickup Date/Time: " +
        //               formattedDate +
        //               " " +
        //               data.pickup_time +
        //               ",\n\nTotal KM : " +
        //               org_distance +
        //               " ,\n\nDuration : " +
        //               org_duration +
        //               " ,\n\nRate Per KM: " +
        //               listCars.car_amount +
        //               " ₹,\n\nDriver Beta : " +
        //               driverBeta +
        //               " ₹  ,\n\nRate for Oneway : " +
        //               one_way_rate +
        //               "  ₹,\n\nTotal Trip Fare :" +
        //               (parseInt(one_way_rate) + driverBeta) +
        //               " ₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +919597256055 \n\n" +
        //               front_url;
        //             try {
        //               const response = axios.post(
        //                 `https://api.telegram.org/bot${telegramApi}/sendMessage`,
        //                 {
        //                   chat_id: telegramChatId,
        //                   text: message,
        //                 }
        //               );
        //               if (response) {
        //                 setListCars({
        //                   text: "",
        //                   car_amount: 0,
        //                 });
        //                 setBoadingButton(false);
        //                 formRef.current.reset();
        //                 document.body.classList.add("dis_scroll");
        //                 setPopup(true);
        //                 setSelectedDate(null);

        //               }
        //             } catch (error) {
        //               setBoadingButton(false);
        //               alert("Please try again");
        //               console.error("Error sending Telegram message:", error);
        //               // Handle the error
        //             }
        //             try {
        //               axios.post(
        //                 `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
        //                 {
        //                   chat_id: netbixTelegramChatId,
        //                   text: message,
        //                 }

        //               );
        //               // if (response) {
        //               //   setListCars({
        //               //     text: "",
        //               //     imageSrc: "",
        //               //     car_amount: 0,
        //               //   });
        //               //   setBoadingButton(false);
        //               //   formRef.current.reset();
        //               //   document.body.classList.add("dis_scroll");
        //               //   setPopup(true);
        //               //   setSelectedDate(null);

        //               // }

        //             } catch (error) {
        //               console.error("Error sending Telegram message:", error);
        //               // Handle the error
        //             }
        //           } else {
        //             alert("Please Try again!");
        //           }
        //         }
        //       } else {
        //         console.error("Error:", status);
        //       }
        //     }
        //   );

    };
    function popUpClose() {
        document.body.classList.remove("dis_scroll");
        setPopup(false);
    }
    const selectCar = (text, car_amount, carDriverBeta) => {
        setListCars({
            text,
            car_amount,
        });
        setDriverBeta(carDriverBeta);
        setCarList(false);
        setCarImages(text);
        // setCarImages((prevImages) => ({
        //   ...prevImages,
        //   [text]: prevImages[text] === defaultImage ? altImage : defaultImage,
        // }));

    };

    return (
        <div className="mt-6 ">
            < form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1">
                    <div className="p-3 pb-0">

                        <input
                            type="text"
                            className={`w-full py-3 px-4 focus:outline-0 border  ${errors.pick_up_add ? " border-red-400" : "border-b-0"
                                }`}
                            placeholder="Enter your pickup point"
                            name="pick_up_add"
                            value='Vellore'
                            {...register("pick_up_add", { required: true })}
                        />
                        {/* <select className={`w-full py-3 px-4 focus:outline-0 border  ${errors.pick_up_add ? " border-red-400" : "border-b-0"
                }`} 
                name="pick_up_add"
              {...register("pick_up_add", { required: true })}
                >
                <option value=''  selected hidden disabled>Enter Your Pickup Point</option>
                <option value='Vellore' >Vellore</option>
                <option value='Chennai'>Chennai</option>
            </select> */}

                        {/* {errors.pick_up_add && (
            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Pick up Address is required.
            </span>
          )} */}
                    </div>
                    <div className="p-3 pt-0">


                        <input
                            type="text"
                            className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                                errors.pick_up_add &&
                                " border-red-400 border-t-0"
                                } ${errors.drop_add && !errors.pick_up_add && " border-red-400 "
                                }`}
                            placeholder="Enter your drop point"
                            name="drop_add"
                            value={drop_value}
                            {...register("drop_add", { required: true })}
                        />
                        {/* <select className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.pick_up_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.pick_up_add && " border-red-400 "
                }`} 
                name="drop_add"
                {...register("drop_add", { required: true })}
                >
                <option value=''  selected hidden disabled>Enter your drop point</option>
                <option value='vellore'>Vellore</option>
                <option value='chennai'>Chennai</option>
            </select> */}

                    </div>
                    <div className=" grid grid-cols-2 border mx-3  bg-black">
                        <div className="px-3">
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                                className={`w-full py-3 px-4 focus:outline-0 ${!selectedDate &&
                                    errors.pickup_dates &&
                                    "border border-red-500"
                                    }`}
                                placeholderText="Pick up Date"
                                name="pickup_date"
                                inputMode="none"
                                onFocus={(e) => e.target.blur()} 
                            />


                            <input
                                type="hidden"
                                value={selectedDate ? selectedDate : ' '}
                                name="pickup_dates"
                                {...register("pickup_dates", { required: true })}
                            />
                        </div>
                        <div className="px-3">
                            <select
                                className={`w-full py-3 px-4 focus:outline-0 ${errors.pickup_time && " border border-red-500"
                                    }`}
                                id="pickup_time"
                                name="pickup_time"
                                {...register("pickup_time", { required: true })}
                            >
                                <option value="" selected hidden disabled>Pickup Time</option>

                                <option value="12:00 AM">12:00 AM</option>

                                <option value="12:30 AM">12:30 AM</option>

                                <option value="01:00 AM">01:00 AM</option>

                                <option value="01:30 AM">01:30 AM</option>

                                <option value="02:00 AM">02:00 AM</option>

                                <option value="02:30 AM">02:30 AM</option>

                                <option value="03:00 AM">03:00 AM</option>

                                <option value="03:30 AM">03:30 AM</option>

                                <option value="04:00 AM">04:00 AM</option>

                                <option value="04:30 AM">04:30 AM</option>

                                <option value="05:00 AM">05:00 AM</option>

                                <option value="05:30 AM">05:30 AM</option>

                                <option value="06:00 AM">06:00 AM</option>

                                <option value="06:30 AM">06:30 AM</option>

                                <option value="07:00 AM">07:00 AM</option>

                                <option value="07:30 AM">07:30 AM</option>

                                <option value="08:00 AM">08:00 AM</option>

                                <option value="08:30 AM">08:30 AM</option>

                                <option value="09:00 AM">09:00 AM</option>

                                <option value="09:30 AM">09:30 AM</option>

                                <option value="10:00 AM">10:00 AM</option>

                                <option value="10:30 AM">10:30 AM</option>

                                <option value="11:00 AM">11:00 AM</option>

                                <option value="11:30 AM">11:30 AM</option>

                                <option value="12:00 PM">12:00 PM</option>

                                <option value="12:30 PM">12:30 PM</option>

                                <option value="01:00 PM">01:00 PM</option>

                                <option value="01:30 PM">01:30 PM</option>

                                <option value="02:00 PM">02:00 PM</option>

                                <option value="02:30 PM">02:30 PM</option>

                                <option value="03:00 PM">03:00 PM</option>

                                <option value="03:30 PM">03:30 PM</option>

                                <option value="04:00 PM">04:00 PM</option>

                                <option value="04:30 PM">04:30 PM</option>

                                <option value="05:00 PM">05:00 PM</option>

                                <option value="05:30 PM">05:30 PM</option>

                                <option value="06:00 PM">06:00 PM</option>

                                <option value="06:30 PM">06:30 PM</option>

                                <option value="07:00 PM">07:00 PM</option>

                                <option value="07:30 PM">07:30 PM</option>

                                <option value="08:00 PM">08:00 PM</option>

                                <option value="08:30 PM">08:30 PM</option>

                                <option value="09:00 PM">09:00 PM</option>

                                <option value="09:30 PM">09:30 PM</option>

                                <option value="10:00 PM">10:00 PM</option>

                                <option value="10:30 PM">10:30 PM</option>

                                <option value="11:00 PM">11:00 PM</option>

                                <option value="11:30 PM">11:30 PM</option>
                            </select>
                        </div>
                    </div>
                    {/* <div className=" border mx-3  py-3 pl-6 border-t-0 bg-black">
          <span
            onClick={() => tripType(!fromChange)}
            className="bg-[#1D5DA4] py-2 px-3 rounded-3xl text-white text-sm cursor-pointer">Round Trip?</span>
        </div> */}
                    {/* <div className=" grid grid-cols-2 border mx-3 mt-3">
         <div className=" relative">
          <span className=" absolute top-1/2 left-2 -translate-y-1/2"><FaUser className="  text-white"  /></span>
          <select
              className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                errors.passengers && " border border-red-500"
              }`}
              id="pickup_time"
              name="passengers"
              {...register("passengers", { required: true })}
            >
              <option selected value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
         </div>
         <div className=" relative">
          <span className=" absolute top-1/2 left-2 -translate-y-1/2"><MdLuggage size={20} className=" text-white"  /></span>
          <select
              className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                errors.luggages && " border border-red-500"
              }`}
              id="pickup_time"
              name="luggages"
              {...register("luggages", { required: true })}
            >
              <option selected value="0">0</option>
              <option  value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
         </div>
        </div> */}
                    <div className="p-3">
                        <div className=" relative">
                            <span
                                className={`top-1/2 left-0 h-full px-2 border text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${errors.mob_number && "border border-red-500"
                                    }`}
                            >
                                <span>
                                    <img src={indiaFlag} className="w-6" />
                                </span>
                                <span className=" text-white">+91</span>
                            </span>
                            <input
                                type="int"
                                className={`w-full border  py-3 px-4 focus:outline-0 pl-20 ${errors.mob_number && "border border-red-500"
                                    }`}
                                {...register("mob_number",
                                    {
                                        required: "Mobile Number is required", minLength: { value: 10, message: "Requied 10 digit" },
                                        maxLength: { value: 10, message: "Requied only 10 digit" }
                                    })}
                                placeholder="Mobile number"
                                name="mob_number"
                            />
                        </div>
                        {errors.mob_number ?
                            <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number Should Be 10</span> : ''}

                    </div>

                    <div className="p-3">
                        <input
                            type="text"
                            className={`w-full border  py-3 px-4 focus:outline-0 ${errors.full_name && "border border-red-500"
                                }`}
                            placeholder="Enter your Name"
                            name="full_name"
                            {...register("full_name", { required: true })}
                        />
                    </div>
                    {/* <div className="p-3">
          <input
            type="number"
            className={`w-full border  py-3 px-4 focus:outline-0 ${
              errors.mob_number && "border border-red-500"
            }`}
            placeholder="Mobile number"
            name="mob_number"
            {...register("mob_number", { required: true })}
          />
        </div> */}
                </div>
                <div className="p-3 ">
                    <div className="text-center font-bold text-xl">Choose Vehicle</div>
                    {/* <input
          type="hidden"
          name="car_amount"
          value={listCars.car_amount != 0 ? listCars.car_amount : 0}
        />
        <div
          className={`p-3 px-4  cursor-pointer relative border bg-black text-white ${carReq && "border-red-500"}`}
        > 
          {listCars.text ? listCars.text : "Select Car Type"}{" "}
          <img
            src={listCars.imageSrc ? listCars.imageSrc : selct_car}
            alt="select car"
            className=" w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2"
          />
        </div> */}
                    <div className={`flex gap-5 justify-center flex-wrap mt-3`}>

                        <div className={`flex flex-col w-[25%] p-2 border rounded-md ${carImages === 'SEDAN' ? 'bg-blue-500 text-white' : 'bg-white'
                            }`}
                            onClick={() => selectCar("SEDAN", 14, 2800)}
                        >


                            <span className="text-center text-[10px] md:text-[16px]">₹2800</span>
                            <img
                                src={sedan}
                                alt="sedan"
                                className="w-full cursor-pointer"

                            />
                            <span className="text-center text-[10px] md:text-[16px]">SEDAN</span>

                            <span className="flex   gap-3 justify-center"><MdAirlineSeatReclineNormal size={30} className="text-blue-500 " /> 4 + 5</span>

                        </div>
                        {/* <div className={`flex flex-col w-[20%] p-2 border rounded-md ${carImages === 'ETIOS' ? 'bg-blue-500 text-white' : 'bg-white'
                            }`}
                            onClick={() => selectCar("ETIOS", 14, 400)}

                        >
                            <span className="text-center text-[10px] md:text-[16px] ">14₹ /km</span>
                            <img
                                src={etios}
                                alt="etios"
                                className="w-full cursor-pointer"
                            />
                            <span className="text-center text-[10px] md:text-[16px]">ETIOS</span>
                        </div> */}
                        <div className={`flex flex-col w-[25%] p-2 border rounded-md ${carImages === 'SUV' ? 'bg-blue-500 text-white' : 'bg-white'
                            }`}
                        >
                            <span className="text-center text-[10px] md:text-[16px] ">₹ 3600</span>
                            <img
                                src={suv}
                                alt="suv"
                                className="w-full cursor-pointer"
                                onClick={() => selectCar("SUV", 19, 3600)}
                            />
                            <span className="text-center text-[10px] md:text-[16px]">SUV</span>


                            <span className="flex   gap-3 justify-center"><MdAirlineSeatReclineNormal size={30} className="text-blue-500 " /> 7 + 1 </span>
                        </div>
                        {/* <div className={`flex flex-col w-[20%] p-2 border rounded-md ${carImages === 'INNOVA' ? 'bg-blue-500 text-white' : 'bg-white'
                            }`}
                        >
                            <span className="text-center text-[10px] md:text-[16px] ">20₹ /km</span>
                            <img
                                src={innova}
                                alt="innova"
                                className="w-full cursor-pointer"
                                onClick={() => selectCar("INNOVA", 20, 400)}
                            />
                            <span className="text-center text-[10px] md:text-[16px]">INNOVA</span>
                        </div> */}


                    </div>
                    {/* <div>
        <div
          className={`p-3 px-4  cursor-pointer relative border bg-black text-white ${carReq && "border-red-500"}`}
          onClick={openCars}
        >
          {listCars.text ? listCars.text : " "}{" "}

           <img
              src={sedan}
              alt="sedan"
              className="w-[97px] h-auto"
              onClick={() => selectCar("SEDAN", sedan, 14, 400)
             }
            />
        </div>
        </div>
        <div>

            <img
              src={etios}
              alt="etios"
              className="w-[97px] h-auto"
            onClick={() => selectCar("ETIOS", etios, 15, 400)}
            />
        </div>
        <div>
            <img
              src={suv}
              alt="suv"
              className="w-[97px] h-auto"
              onClick={() => selectCar("SUV", suv, 19, 500)}
            />
        </div>
        <div>
            <img
              src={innova}
              alt="innova"
              className="w-[97px] h-auto"
              onClick={() => selectCar("INNOVA", innova, 20, 400)}
            />
          </div> */}

                    {/* <div className={` text-white border bg-black"}`}>
  <img
              src={sedan}
              alt="sedan"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              onClick={() => selectCar("SEDAN", sedan, 14, 400)}

            />
                          <img
              src={etios}
              alt="etios"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              onClick={() => selectCar("ETIOS", etios, 14, 400)}

            />

<img
              src={suv}
              alt="suv"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              onClick={() => selectCar("SUV", suv, 19, 400)}
            />
            <img
              src={innova}
              alt="innova"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              onClick={() => selectCar("INNOVA", innova, 20, 400)}

            />

    </div> */}


                    {/* <ul className={` text-white border bg-black ${carList ? "" : "hidden"}`}>
          <li
            className="py-3 px-4 border-b-2 relative cursor-pointer"
            onClick={() => selectCar("SEDAN", sedan, 14, 400)}
          >
            SEDAN{" "}
            <img
              src={sedan}
              alt="sedan"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
            />
          </li>
          <li
            className="py-3 px-4 border-b-2 relative cursor-pointer"
            onClick={() => selectCar("ETIOS", etios, 14, 400)}
          >
            ETIOS{" "}
            <img
              src={etios}
              alt="etios"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
            />
          </li>
          <li
            className="py-3 px-4 border-b-2 relative cursor-pointer"
            onClick={() => selectCar("SUV", suv, 19, 400)}
          >
            SUV{" "}
            <img
              src={suv}
              alt="suv"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
            />
          </li>
          <li
            className="py-3 px-4 border-b-2 relative cursor-pointer"
            onClick={() => selectCar("INNOVA", innova, 20, 400)}
          >
            INNOVA{" "}
            <img
              src={innova}
              alt="innova"
              className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
            />
          </li>
        </ul> */}
                    {carReq ? (
                        <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                            Car is required.
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="text-center mt-5">
                    <button className="mt-4py-3 px-6 th-btn uppercase">Get Estimation</button>
                    {/* <button className="bg-[#e30d16] mt-4 md:mt-6 text-white py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear">
          Get Estimation
        </button> */}
                </div>
            </form>
            {popup ? (
                <div className="fixed top-0 left-0  h-full w-full z-[100]">
                    <div className="w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5">
                        <span
                            className="fixed top-2 right-2 cursor-pointer"
                            onClick={popUpClose}
                        >
                            <AiOutlineCloseCircle className="text-[20px]" />
                        </span>
                        <h2 className="text-[30px]">
                            Trip Estimation{" "}
                            <span className="primary-color pop-shadow"> Rs.{popupRate}</span>
                        </h2>
                        <div>
                            <div className="grid grid-cols-2 py-3 mt-4 ">
                                <div className="pl-2 border-b-2 border-t-2 border-l-2 py-3">
                                    Total Distance :
                                </div>
                                <div className="pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3">
                                    {popupDis}
                                </div>
                                <div className="pl-2 border-b-2 border-l-2 py-3">
                                    Total Duration
                                </div>
                                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                                    {popupDur}
                                </div>
                                <div className="pl-2 border-b-2 border-l-2 py-3">
                                    Selected Car Type
                                </div>
                                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                                    {popupCar}
                                </div>
                                <div className="pl-2 border-b-2 border-l-2 py-3">
                                    Driver Allowance :
                                </div>
                                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                                    Included
                                </div>
                                <div className="pl-2 border-b-2 border-l-2 py-3">
                                    Total Amount :
                                </div>
                                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                                    Rs. {totRate}
                                </div>
                                <div className="pl-2 border-b-2 border-l-2 py-3">
                                    Driver Beta :
                                </div>
                                <div className="pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3">
                                    Rs. {driverBeta}
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                <button
                                    onClick={sendWhatsapp}
                                    className="th-btn-reverse py-3 text-white px-6 rounded-md"
                                >
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>

    )
}

export default OneWayPlacesForm