import React from 'react'
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
import { FaPhone } from "react-icons/fa6";
const WhyChooseUs = () => {
  return (
    <div>  <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
    <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
      Point-to-Point Drop Taxi Services        </h4>
    <div className=" flex flex-col md:flex-row lg:flex-row justify-around mt-3">
      <div className="pt-4">
        <h2 className="text-2xl  md:text-3xl lg:text-3xl font-medium">Vellore to Chennai</h2>
        <div className="flex flex-col gap-y-[10px] pt-3 pl-3">

          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Direct Travel</h3>
          <p>
            Enjoy a seamless and direct ride from Vellore to Chennai.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Fixed Rates</h3>
          <p>
            Transparent pricing with no hidden fees.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Comfort & Safety</h3>
          <p>
            Clean, sanitized cars driven by professional drivers.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  24/7 Availability</h3>
          <p>
            Book anytime for your convenience.          </p>
        </div>
      </div>

      <div className="pt-4">
        <h2 className="text-2xl  md:text-3xl lg:text-3xl font-medium font-medium">Chennai to Vellore</h2>
        <div className="flex flex-col gap-y-[10px] pt-3 pl-3">

          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Convenient Return Trips</h3>
          <p>
            Reliable drop service from Chennai to Vellore.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•  Same High Standards</h3>
          <p>
            Consistent quality of service and vehicle cleanliness.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl">•	Flexible Scheduling</h3>
          <p>
            Choose your preferred time and date for travel.          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4 pl-3">
          <h3 className="font-semibold text-xl md:text-2xl lg:text-2xl" >•  Easy Online Booking</h3>
          <p>Simple and quick booking process </p>
        </div>

      </div>
    </div>
    <div>    <p className='text-black text-center opacity-60 md:text-lg mt-5'>
      Travel comfortably and safely with our point-to-point drop taxi services between Vellore and Chennai. Book your ride now!
    </p>
      <div className=' mt-5 flex justify-center gap-x-3'>
        <div>
          <a href="#book_taxi_form" className='th-btn'>Book Now</a></div>
        <div className=" text-white font-bold text-lg">
          <a
            href="tel:+919597256055"
            className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-2 py-2"
          >
            <span className=" flex gap-x-2 items-center justify-center">
              <FaPhone size={17} /> +91 9597256055
            </span>
          </a>
        </div>

      </div>

    </div>

  </div>
  <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
    <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
      <div className=" flex flex-col gap-y-1">
        <img src={img1} className=" w-[80px] mx-auto" />
        <p className=" text-lg md:text-xl font-semibold">
          Easy Online Booking
        </p>
        <p>Pickup and Drop on time</p>
      </div>
      <div className=" flex flex-col gap-y-1">
        <img src={img2} className=" w-[80px] mx-auto" />
        <p className=" text-lg md:text-xl font-semibold">
          Professional Drivers
        </p>
        <p>Well Trained years of experience</p>
      </div>
      <div className=" flex flex-col gap-y-1">
        <img src={img3} className=" w-[80px] mx-auto" />
        <p className=" text-lg md:text-xl font-semibold">
          Variety of Prime Brands
        </p>
        <p>SWIFT - ETIOS - INNOVA</p>
      </div>
      <div className=" flex flex-col gap-y-1">
        <img src={img4} className=" w-[80px] mx-auto" />
        <p className=" text-lg md:text-xl font-semibold">
          Secure Online Payment
        </p>
        <p>24 /7 Customer Support</p>
      </div>
    </div>
  </div></div>
  )
}

export default WhyChooseUs